<template>
  <v-container class="fill-heigt" fluid>
    <v-row class="justify-center" style="margin-top: -45px">
      <v-col cols="12" md="8">
        <v-alert
          v-model="alert"
          color="primary_text"
          dense
          type="info"
          class="text-center black--text caption mb-0"
          dismissible
          >{{ $t("alert_interview") }}</v-alert
        >
      </v-col>
    </v-row>

    <v-row>
      <div>
        <v-btn icon color="primary" @click="goBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
    </v-row>

    <v-row class="mt-12">
      <v-col cols="12" md="6">
        <v-card class="">
          <v-card-title class="mb-2"
            >{{ $t("scheduled_interviews") }}
            <v-spacer></v-spacer>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  small
                  @click="openScheduleInterviewDialog()"
                  style="margin-left: 5px"
                >
                  <v-icon left>mdi-calendar</v-icon
                  >{{ $t("schedule_interview") }}
                </v-btn>
              </template>
              <div v-html="$t('tooltip_interview')"></div>
            </v-tooltip>
          </v-card-title>
          <v-card
            class="whirelab-pink pt-5"
            style="min-height: 505px"
            :to="{
              name: 'scheduled.interviews',
              params: { id: interview.id },
            }"
          >
            <template v-if="loadingData">
              <panel-card-preview-loader v-for="i in 5" :key="i" />
            </template>
            <!-- <panel-card-preview-loader /> -->
            <template v-else>
              <!-- <template> -->
              <v-card class="whirelab-pink px-5" elevation="0">
                <template v-for="interview in interviews">
                  <panel-card-preview
                    :interview="interview"
                    :key="interview.timestemp"
                    class="mb-1"
                  />
                </template>
              </v-card>
            </template>
          </v-card>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card
          :to="{
            name: 'approved.interviews',
            params: { id: interview.id },
          }"
        >
          <v-card-title class="mb-2">{{
            $t("approved_candidates")
          }}</v-card-title>
          <v-card-text class="whirelab-pink pa-10 pt-12">
            <v-row v-if="loadingData" style="min-height: 500px">
              <v-col cols="12" md="6">
                <approved-candidate-card-loader />
              </v-col>
            </v-row>

            <v-row
              v-if="approvedCandidates.length > 0"
              style="min-height: 500px"
            >
              <v-col
                v-for="candidate in approvedCandidates"
                :key="candidate.id"
                cols="12"
                md="6"
              >
                <approved-candidate-card :candidate="candidate" />
              </v-col>
            </v-row>

            <v-row v-else class="d-flex justify-center align-center">
              <div class="default-plus"><v-icon x-large>mdi-plus</v-icon></div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <add-candidate-to-interview @added="addCandidate" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { GET_INTERVIEW_BY_ID_QUERY } from "../graphql/Query.gql";
import { UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION } from "../graphql/Subscraption.gql";
import { groupBy } from "@/utils";
import PanelCardPreview from "../components/panel/PanelCardPreview.vue";
import ApprovedCandidateCard from "../components/candidate/ApprovedCandidateCard.vue";
import PanelCardPreviewLoader from "../components/skeleton-loader/PanelCardPreviewLoader.vue";
import ApprovedCandidateCardLoader from "../components/skeleton-loader/ApprovedCandidateCardLoader.vue";
import AddCandidateToInterview from "../components/form/AddCandidateToInterview.vue";
export default {
  name: "InterviewPreview",

  components: {
    PanelCardPreview,
    ApprovedCandidateCard,
    PanelCardPreviewLoader,
    ApprovedCandidateCardLoader,
    AddCandidateToInterview,
  },

  data: () => ({
    alert: true,
    interview: {},
    loadingData: true,
  }),

  apollo: {
    interview: {
      query: GET_INTERVIEW_BY_ID_QUERY,
      variables() {
        return { id: this.$attrs.id };
      },
      fetchPolicy: "no-cache",
      result({ loading }) {
        if (!loading) {
          this.loadingData = false;
        }
      },
    },
    $subscribe: {
      updateInterviewSchedule: {
        query: UPDATE_INTERVIEW_SCHEDULE_SUBSCRIPTION,
        result({ data }) {
          this.interview.candidates = data.updateInterviewSchedule.candidates;
        },
      },
    },
  },

  computed: {
    ...mapGetters({
      getBlockedCandidates: "candidate/getBlockedCandidates",
    }),
    interviews() {
      const blockeds = [];
      const candidates = this.interview.candidates;
      let list = candidates
        ? candidates.map((findCandidate) => {
            const findIndex = blockeds.findIndex(
              (blockedId) =>
                findCandidate.interviewee &&
                blockedId == findCandidate.interviewee.id
            );
            const stage = findCandidate.stages.find(
              (findStage) => findStage.name === findCandidate.currentStage
            );
            return {
              ...findCandidate,
              address: this.interview.address,
              subject: this.interview.subject,
              telephone: this.interview.telephone,
              timestemp: `${stage.date} ${stage.time} ${stage.groupBy}`,
              blocked: findIndex > -1,
              sort: this.status(findCandidate),
            };
          })
        : [];

      list = list.filter(
        (filterCandidate) =>
          filterCandidate.status !== "approved" &&
          filterCandidate.status !== "rejected" &&
          filterCandidate.status !== "canceled" &&
          filterCandidate.status !== "finished"
      );

      list.sort((a, b) => a.sort - b.sort);

      let resultItems = groupBy(list, "timestemp", (record, dateKey) => {
        return [record[dateKey]];
      });

      resultItems = groupBy(list, "timestemp", (record, dateKey) => {
        return [record[dateKey]];
      });

      return resultItems;
    },
    approvedCandidates() {
      const blockeds = [];
      const candidates = this.interview.candidates;
      let resultItems = candidates
        ? candidates.map((findCandidate) => {
            const findIndex = blockeds.findIndex(
              (blockedId) =>
                findCandidate.interviewee &&
                blockedId == findCandidate.interviewee.id
            );
            const stage = findCandidate.stages.find(
              (findStage) => findStage.name === findCandidate.currentStage
            );
            return {
              ...findCandidate,
              address: this.interview.address,
              subject: this.interview.subject,
              telephone: this.interview.telephone,
              timestemp: `${stage.date} ${stage.time} ${stage.groupBy}`,
              blocked: findIndex > -1,
            };
          })
        : [];

      resultItems = resultItems.filter(
        (filterCandidate) => filterCandidate.status === "approved"
      );

      return resultItems;
    },
  },

  methods: {
    addCandidate(candidates) {
      this.interview.candidates = candidates;
    },

    openScheduleInterviewDialog() {
      // eslint-disable-next-line no-undef
      Fire.$emit("AddCandidateToInterview", this.interview);
    },

    goBack() {
      this.$router.push({
        name: "interviews",
      });
    },

    status(candidate) {
      console.log({ candidate });
      let states = [];
      candidate.stages.forEach((mapStage) => states.push(mapStage.state));

      const STATUS = states;
      const TOTAL_STATUS = STATUS.length;
      let statusIndex = 0;
      let pending = 0,
        ongoing = 0;

      for (statusIndex; statusIndex < TOTAL_STATUS; statusIndex++) {
        switch (STATUS[statusIndex]) {
          case "pending":
            pending += 1;
            break;

          default:
            ongoing += 1;
            break;
        }
      }

      if (TOTAL_STATUS === pending) {
        return 1;
      } else if (TOTAL_STATUS === ongoing) {
        return 2;
      }

      return 1;
    },
  },
};
</script>

<style scoped>
.label-status {
  border: 1px solid black;
}

.whirelab-pink {
  background-color: #daf4fd;
  min-height: 200px;
}

.default-plus {
  margin: 200px;
}
</style>